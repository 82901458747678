import { clientSplitApi } from '../clientSplitApi'
import {ApiModelResponse, ApiModelsResponse} from "../../common/types";

export const apiWithLocations = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<ApiModelsResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/locations`,
          params: {
            context: JSON.stringify(args)
          }
        }
      },
      providesTags: ['Location']
    }),
    getLocation: build.query<ApiModelResponse, Record<string, any>>({
      query: ({id}) => `/locations/${id}`,
      providesTags: ['Location']
    }),
    getLocationApprovalWizard: build.query<ApiModelResponse, Record<string, any>>({
      query: ({id}) => `/locations/${id}/approval-wizard`,
    }),
    updateLocation: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id, ...data}) {
        return {
          url: `/locations/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Location']
    }),
    updateLocationCurrency: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id, ...data}) {
        return {
          url: `/locations/${id}/currency`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Location']
    }),
    createLocation: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `/locations`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Location']
    }),
    hideLocation: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id}) {
        return {
          url: `/locations/${id}/hide`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Location']
    }),
    deleteLocation: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id}) {
        return {
          url: `/locations/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Location']
    }),
    sendApprovalRequest: build.mutation<ApiModelResponse, Record<string, any>>({
      query({location_id}) {
        return {
          url: `/locations/${location_id}/send-approval-request`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Location']
    }),
    sendTrialRequest: build.mutation<ApiModelResponse, Record<string, any>>({
      query({location_id}) {
        return {
          url: `/locations/${location_id}/send-trial-request`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Location']
    }),
  }),
  overrideExisting: false,
});


