import {useCallback} from 'react';
import {useDirtyFieldsStore} from "../useDirtyFieldsStore";
import {useLeavePageConfirmation} from "./useLeavePageConfirmation";
import {confirm} from "components/shared/confirmation/confirm";

const DirtyCheck = () => {
  const {hasAnyDirtyFields: isDirty, reset: resetDirtyFields} = useDirtyFieldsStore();

  const defaultConfirmationDialog = useCallback(async (msg: string) => {
    const result = await confirm(msg, {confirmLabel: 'Leave without saving', cancelLabel: 'Stay'});
    if (result) {
      resetDirtyFields();
    }

    return result;
  }, [resetDirtyFields]);

  useLeavePageConfirmation(isDirty, 'You have unsaved changes. Are you sure you want to leave the page?', defaultConfirmationDialog);

  return null
};

export default DirtyCheck;