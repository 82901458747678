import { createTheme } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    text: {
      primary: '#6B7280',
      secondary: 'rgba(49,52,53,0.6)',
      disabled: 'rgba(49,52,53,0.4)',
    },
    primary: {
      main: '#3B82F6',
    },
    secondary: {
      main: 'rgba(49,52,53,0.6)',
    },
    error: {
      main: red[400],
    },
    success: {
      main: green[400],
    },
    light: {
      main: '#fff',
      contrastText: '#C82222',

      light: '#fff',
      dark: '#f3f3f3',
    },
    neutral: {
      main: '#313435',
      contrastText: '#fff',
    },
  },
  typography: {
    // htmlFontSize: 10,
    // fontSize: 12,

    fontFamily: "'Source Sans Pro', sans-serif",

    button: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1766FF',
          // fontSize: 12,
          fontWeight: 600,
          textDecoration: 'none',
          // lineHeight: '100%',
          '&:hover': {
            textDecoration: 'underline',
          }
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    light: Palette['primary'];
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    light?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    light: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    light: true;
  }
}

export default theme;
