import React, {ReactNode} from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import HistoryIcon from '@mui/icons-material/History';
import ArchiveIcon from '@mui/icons-material/Archive';
import {array} from "yup";
import Link from "Link";
import {Divider, ListItemIcon, ListItemText, ListSubheader, MenuListProps} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

interface SectionsMenuProps {
  children: ReactNode,
  sections: any[],
  MenuListProps?: MenuListProps
  subheader?: string
}

const SectionsMenu = (props: SectionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        color='neutral'
        endIcon={<ArrowDropDownOutlinedIcon />}
        onClick={handleClick}
        sx={{padding: '3px 8px'}}
      >
        {props.children}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{subheader: !!props.subheader ? (<ListSubheader component="div">
            {props.subheader}
          </ListSubheader>) : undefined}}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.12))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
              borderTop: '1px solid rgba(0, 0, 0, 0.12)'
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!!props.sections?.length && props.sections.map((section, index) => (
          <MenuItem
            component={Link}
            href={section.url}
            key={section.url}
          >
            {section.title}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          component={Link}
          href={'/distributor/profile/exports'}
        >
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export History</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          href={'/logout'}

        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>


  )
}

export default SectionsMenu