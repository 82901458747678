import {distributorSplitApi} from "../distributorSplitApi";
import {ApiModelsResponse, ApiModelResponse} from "../../common/types";

export const apiWithProfile = distributorSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getLayoutPermissions: build.query<ApiModelResponse, void>({
      query: () => '/permissions/layout',
      providesTags: ['permissions-layout']
    }),
    getUserDefaultPresets: build.query<ApiModelsResponse, void>({
      query: () => '/profile/presets/defaults',
      providesTags: ['preset']
    }),
  }),
  overrideExisting: false,
});


