import {apiWithLocations} from "../../../../services/client/locations/locations";
import {LinearProgress} from "@mui/material";
import Link from "../../../../Link";
import useLocationApprovalWizardStore from "./useLocationApprovalWizardStore";
import FullMode from "./FullMode";
import CollapsedMode from "./CollapsedMode";
import WizardStep from "./WizardStep";
import Box from "@mui/material/Box";
import {ReactChild, useCallback, useEffect} from "react";
import {useRouter} from "next/router";
import styled from "@emotion/styled";

const LinkStyled = styled(Box)`
  display: inline;
  cursor: pointer;
  
  text-decoration: underline;
  text-decoration-color: rgba(0, 111, 186, 0.4);
  color: #006FBA;
  font-weight: 500;
  
  &:hover {
    text-decoration-color: inherit;
  }
`

interface LinkButtonProps {
  children: ReactChild
  href: string
}

const LinkButton = (props: LinkButtonProps) => {
  const router = useRouter()
  const {setMode} = useLocationApprovalWizardStore()

  const handleClick = useCallback(() => {
    console.log('handleClick', props.href)

    setMode('collapsed')

    router.push(props.href, props.href, { shallow: true })
  }, [router, props])

  return <LinkStyled
    onClick={handleClick}
  >
    {props.children}
  </LinkStyled>
}

const LocationApprovalWizard = () => {
  const {show, mode, locationId, setShow, changed} = useLocationApprovalWizardStore()

  const {data, isLoading, refetch, isUninitialized} = apiWithLocations.useGetLocationApprovalWizardQuery({id: locationId}, {
    skip: !locationId,
    refetchOnMountOrArgChange: true,
    pollingInterval: 10 * 60 * 1000, // 10 min
  })

  useEffect(() => {
    if (!isUninitialized) {
      refetch()
    }
  }, [changed, isUninitialized])

  if (!show || !locationId) {
    return null
  }

  if (isLoading) {
      return <LinearProgress />
  }

  const options = [
    {
      show: data?.data?.options?.company_data?.show,
      component: <WizardStep
        status={data?.data?.options?.company_data?.status}
        name={<>Fill in all required data in the <LinkButton href={"/client/company#main-info"}>"My Company"</LinkButton> section</>}
        description={'Information in "My company" will serve as a legal basis.'}
      />
    },
    {
      show: data?.data?.options?.location_data?.show,
      component: <WizardStep
        status={data?.data?.options?.location_data?.status}
        name={<>Fill in all required fields in the <LinkButton href={`/client/locations/${locationId}/edit#contact-info`}>"Locations"</LinkButton> section</>}
        description={'Information in “Locations” will contain the physical address of the venue. In case of expanding your business and having multiple locations, this will be helpful to quickly differentiate data on commercial sessions launched in the monthly bills per each venue separately.'}
      />
    },
    {
      show: data?.data?.options?.license?.show,
      component: <WizardStep
        status={data?.data?.options?.license?.status}
        name={<>Choose the type of license in <LinkButton href={`/client/locations/${locationId}/edit#arvi-vr-license`}>"License Settings"</LinkButton></>}
        description={'To enable commercial sessions launch you must pick the most suitable License Settings. In other words, you will pick the way you will be charged for the content usage.'}
      />,
    },
    {
      show: data?.data?.options?.rs_pricing?.show,
      component: <WizardStep
        status={data?.data?.options?.rs_pricing?.status}
        name={<>Set the prices in <LinkButton href={`/client/locations/${locationId}/edit#pricing`}>"Pricing"</LinkButton> section</>}
        description={'Please set the prices to enable Revenue Share License Settings. You may also set discounts.'}
      />,
    }
  ]

  if (mode === 'collapsed') {
    return <CollapsedMode options={options} onClose={() => setShow(false)} />
  }

  return <FullMode options={options} onClose={() => setShow(false)} />
}

export default LocationApprovalWizard
