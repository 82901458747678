import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// initialize an empty api service that we'll inject endpoints into later as needed
export const commonSplitApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_HOST}/api/v2/common`,
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include",
  }),
  endpoints: () => ({}),
  tagTypes: [
    'export',
  ],
})

// export const splitApi = emptySplitApi as ApiWithInjectedEndpoints<
//   typeof emptySplitApi,
//   [
//     // these are only type imports, no runtime imports -> no bundle dependence
//     typeof import('./locations/locations').apiWithLocations,
//     typeof import('./locations/location-prices').apiWithLocationPrices,
//   ]
// >;