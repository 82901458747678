import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import SnackbarUtils from "components/shared/notistack/SnackbarUtilsConfigurator";
import compareUrls from "components/shared/helpers/url";
import Router from 'next/router'
import {findBackUrl} from "./customBaseQuery";
// import { Mutex } from 'async-mutex'

/**
 * Log a warning and show a toast!
 */

const exceptErrorCodes = [400, 422]

const redirect = (url: string) => {
  if (compareUrls(window.location.href, url)) {
    return
  }

  // window.location.href = url
  Router.push(url)
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => async (action) => {
    if (action.payload?.actions) {
      const redirect = action.payload.actions.find((a: any) => a?.name === 'redirect')
      if (redirect) {
        redirect(redirect.data.to)

        return next(action)
      }
    } else if (isRejectedWithValue(action)) {
      // if (exceptErrorCodes.includes(action.payload.status)) {
      //   return next(action)
      // }

      if (action.payload.status === 401) {
        const back = findBackUrl()

        redirect(`${process.env.NEXT_PUBLIC_HOST}/auth/login?${back ? `back=${encodeURIComponent(back)}` : ''}`)

        return next(action)
      } else if (action.payload.status === 466) {
        SnackbarUtils.error(action.payload?.data?.data?.message ?? action.payload?.data?.error ?? 'War error')

        redirect(`${process.env.NEXT_PUBLIC_HOST}/client/errors/${action.payload.status}`)

        return next(action)
      }
    }

    return next(action)
  }
