import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import {customBaseQuery} from "../customBaseQuery";

// initialize an empty api service that we'll inject endpoints into later as needed
export const clientSplitApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
  // baseQuery: customBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_HOST}/api/v2/client`,
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include",
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Profile', 'Agreement',
    'Location', 'LocationPrice', 'LocationDiscount', 'Instance',
    'Session', 'Bill', 'Invoice', 'Dispute', 'Transaction', 'DisputeComment', 'Balance', 'PaymentMethod', 'Payment',
    'BalancePaymentMethod', 'Company', 'CompanyNotification',
    'User', 'ArviRoyalty'
  ],
})

// export const splitApi = clientSplitApi as ApiWithInjectedEndpoints<
//   typeof clientSplitApi,
//   [
//     // these are only type imports, no runtime imports -> no bundle dependence
//     typeof import('./billing/bills').apiWithBills,
//   ]
// >;
