import {apiWithProfile} from "services/client/profile/profile";
import {apiWithProfile as apiWithProfileDistributor} from "services/distributor/profile/profile";
import {useCallback} from "react";
import {useRouter} from "next/router";

export const useCurrentUser = () => {
  const router = useRouter()
  const response = apiWithProfile.useGetGeneralInfoQuery()

  const {data, isSuccess, isError, isLoading} = response

  // @ts-ignore
  const {__can: permissions, sections = [], company, ...userAttributes} = data?.data ?? {};

  const can = useCallback((names: string[] | undefined) => {
    if (!names || names.length === 0) {
      return true
    }

    return !!names.find((name) => permissions?.[name] ?? false);
  }, [permissions])

  const isDistributorDevelopmentSection = router.asPath.indexOf('/distributor/development') === 0

  return {user: Object.keys(userAttributes).length > 0 ? userAttributes : null, sections, can, company, isSuccess, isError, isLoading, isDistributorDevelopmentSection}
}

export const can = (userPermissions: {[key: string]: string}, names: string[]) => {
  if (!names || names.length === 0) {
    return true
  }

  return !!names.find((name) => userPermissions?.[name] ?? false);
}

export const useClientLayoutPermissions = () => {
  // @ts-ignore
  const {data} = apiWithProfile.useGetLayoutPermissionsQuery()

  const permissions = data?.data ?? {};

  const check = useCallback((names: string[]) => {
    return can(permissions, names)
  }, [permissions])

  return {permissions, can: check}
}

export const useDistributorLayoutPermissions = () => {
  // @ts-ignore
  const {data} = apiWithProfileDistributor.useGetLayoutPermissionsQuery()

  const permissions = data?.data ?? {};

  const check = useCallback((names?: string[]) => {
    return can(permissions, names ?? [])
  }, [permissions])

  return {permissions, can: check}
}

export const useDeveloperLayoutPermissions = () => {
  return useDistributorLayoutPermissions()
}
