import {create} from 'zustand';
import {useFormContext} from "react-hook-form";
import get from "lodash/get";
import {deepEqual} from "fast-equals";
import toPath from "lodash/toPath";

interface IDirtyFieldsStore {
  hasAnyDirtyFields: boolean
  dirtyFields: Record<string, Record<string, boolean>>

  setFormDirtyFields(formId: string, value: Record<string, boolean>): void

  setFormDirtyField(formId: string, attribute: string, status: boolean): void

  deleteFormDirtyField(formId: string, attribute: string): void

  deleteFormDirtyFields(formId: string): void

  setDirtyFields(value: Record<string, Record<string, boolean>>): void

  reset(): void
}

export const useDirtyFieldsStore = create<IDirtyFieldsStore>(set => ({
  hasAnyDirtyFields: false,
  dirtyFields: {},
  setFormDirtyFields: (formId, value) => set((state) => ({
    ...state,
    dirtyFields: {...state.dirtyFields, [formId]: {...value}}
  })),
  setDirtyFields: (value) => set((state) => ({...state, dirtyFields: value})),
  setFormDirtyField: (formId, attribute, status) => set((state) => {
    const newFormDirtyFields = {...state.dirtyFields[formId]};
    newFormDirtyFields[attribute] = status;

    if (deepEqual(state.dirtyFields[formId], newFormDirtyFields) === false) {
      return {
        ...state,
        dirtyFields: {...state.dirtyFields, [formId]: {...newFormDirtyFields}},
        hasAnyDirtyFields: Object.keys(newFormDirtyFields).length > 0
      };
    }

    return state;
  }),
  deleteFormDirtyField: (formId, attribute) => set((state) => {
    const newFormDirtyFields = {...state.dirtyFields[formId]};

    // delete key by exact match (e.g. 'address')
    delete newFormDirtyFields[attribute];

    // delete all keys by wildcard (e.g. 'address.*')
    Object.keys(newFormDirtyFields).forEach((key) => {
      if (key.startsWith(attribute + '.')) {
        delete newFormDirtyFields[key];
      }
    });

    return {
      ...state,
      dirtyFields: {...state.dirtyFields, [formId]: {...newFormDirtyFields}},
      hasAnyDirtyFields: Object.keys(newFormDirtyFields).length > 0
    };
  }),
  deleteFormDirtyFields: (formId) => set((state) => {
    const newDirtyFields = {...state.dirtyFields};
    delete newDirtyFields[formId];
    return {...state, dirtyFields: {...newDirtyFields}, hasAnyDirtyFields: Object.keys(newDirtyFields).length > 0};
  }),
  reset: () => set(() => ({hasAnyDirtyFields: false, dirtyFields: {}})),
}))

export const useDirtyFieldState = (name: string) => {
  // @ts-ignore
  const {id} = useFormContext();

  const dirtyFields = useDirtyFieldsStore(state => state.dirtyFields);

  const isDirtyDirect = get(dirtyFields, [id, toPath(name).join('.')], false);

  const isDirtyWildcard = Object.keys(dirtyFields[id] || {}).some((key) => {
    return key.startsWith(name + '.');
  });

  return {
    isDirty: isDirtyDirect || isDirtyWildcard,
    isDirtyDirect,
    isDirtyWildcard,
    allDirtyFields: dirtyFields[id] || {},
  }
}

export const useDirtyFormState = (formId: string) => {
  const dirtyFields = useDirtyFieldsStore(state => state.dirtyFields);

  return {
    isDirty: Object.keys(dirtyFields[formId] || {}).length > 0,
  }
}