import {clientSplitApi} from "../clientSplitApi";
import {ApiModelResponse} from "../../common/types";

export const apiWithProfileEmail = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    resend: build.mutation<Record<string, any>, void>({
      query() {
        return {
          url: `profile/email/resend`,
          method: 'POST',
        };
      },
    }),
    verify: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `profile/email/verify`,
          method: 'POST',
          params: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});


