import {clientSplitApi} from "../clientSplitApi";
import {ApiModelResponse} from "../../common/types";

export const apiWithEula = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    content: build.query<ApiModelResponse, void>({
      query: () => 'eula',
    }),
    proceed: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `eula`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Profile'],
    }),
  }),
  overrideExisting: false,
});


