import {create} from 'zustand'

interface ISideMenuStateStore {
  values: Record<number, boolean>
  toggle(id: number): void
}

const useSideMenuState = create<ISideMenuStateStore>((set) => ({
  values: {},
  toggle: (id) => set((state) => ({ values: {...state.values, [id]: !state.values[id]}})),
}))

export default useSideMenuState
