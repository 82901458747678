import {clientSplitApi} from "../clientSplitApi";
import {ApiModelResponse} from "../../common/types";

export const apiWithProfile = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getGeneralInfo: build.query<ApiModelResponse, void>({
      query: () => 'profile/info',
      providesTags: ['Profile']
    }),
    getLayoutPermissions: build.query<ApiModelResponse, void>({
      query: () => 'permissions/layout',
      providesTags: ['permissions-layout']
    }),
    getAvailableDeveloperRolesForUser: build.query<ApiModelResponse, Record<string, any>>({
      query(args) {
        return {
          url: `/profile/available-developer-roles`,
          params: {
            user_id: args?.user_id
          }
        }
      },
      providesTags: []
    }),
    changeGeneralInfo: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `profile/change-info`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Profile'],
    }),
    changePassword: build.mutation<ApiModelResponse, Record<string, any>>({
      query(data) {
        return {
          url: `profile/change-password`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Profile'],
    }),
  }),
  overrideExisting: false,
});


