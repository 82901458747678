// @ts-nocheck

import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import Router from 'next/router'
import {FetchBaseQueryArgs, FetchBaseQueryMeta} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {isRejectedWithValue} from "@reduxjs/toolkit";
import SnackbarUtils from "../components/shared/notistack/SnackbarUtilsConfigurator";
import compareUrls from "../components/shared/helpers/url";

const redirect = (url: string) => {
  if (compareUrls(window.location.href, url)) {
    return
  }

  // window.location.href = url
  Router.push(url)
}

export const findBackUrl = () => {
  let back = null
  if (window.location.pathname === '/auth/login') {
    const urlParams = new URLSearchParams(window.location.search)
    back = urlParams.get('back') ? urlParams.get('back') : null
  } else {
    back = window.location.href
  }

  return back
}

export const customBaseQuery =
  (props?: FetchBaseQueryArgs): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> => {
    const baseQuery = fetchBaseQuery(props)

    return async (args, api, extraOptions) => {
      let result = await baseQuery(args, api, extraOptions)
      console.log('customBaseQuery', result)

      if (result?.data?.actions) {
        const redirect = result?.data?.actions.find((a: any) => a?.name === 'redirect')
        if (redirect) {
          redirect(redirect.data.to)

          return result
        }
      } else if (result.error) {
        if (result.error.status === 401) {
          SnackbarUtils.error(result?.data?.data?.message ?? result?.data?.error ?? 'Access error')

          const back = findBackUrl()

          redirect(`${process.env.NEXT_PUBLIC_HOST}/auth/login?${back ? `back=${encodeURIComponent(back)}` : ''}`)

          return result
        } else if (result.error.status === 466) {
          SnackbarUtils.error(result?.data?.data?.message ?? result?.data?.error ?? 'War error')

          redirect(`${process.env.NEXT_PUBLIC_HOST}/client/errors/${result.error.status}`)

          return result
        }
      }

      return result
    }
  }