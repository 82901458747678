import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { commonSplitApi } from 'services/common/commonSplitApi';
import { clientSplitApi } from 'services/client/clientSplitApi';
import { distributorSplitApi } from 'services/distributor/distributorSplitApi';
// import logger from 'redux-logger'
import {rtkQueryErrorLogger} from "services/rtkQueryErrorLogger";
import {changesSlice} from "services/client/system/changes";

const preloadedState = {};

const rootReducer = combineReducers({
  [changesSlice.name]: changesSlice.reducer,
  [commonSplitApi.reducerPath]: commonSplitApi.reducer,
  [clientSplitApi.reducerPath]: clientSplitApi.reducer,
  [distributorSplitApi.reducerPath]: distributorSplitApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    // .concat(logger)
    .concat(commonSplitApi.middleware)
    .concat(clientSplitApi.middleware)
    .concat(distributorSplitApi.middleware)
    .concat(rtkQueryErrorLogger),
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;