import {ReactElement} from "react";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import {StepContent} from "@mui/material";
import {StepIconProps} from "@mui/material/StepIcon";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface WizardStepProps {
  index?: number
  last?: boolean
  status: string
  name: ReactElement
  description: string
  showDescription: boolean
}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <Box>
      {completed ? (
        <CheckCircleIcon sx={{color: '#00a152'}} />
      ) : (
        <CancelIcon sx={{color: '#b2102f'}} />
      )}
    </Box>
  );
}

const WizardStep = (props: WizardStepProps) => {
  return <Step
    completed={props.status === 'passed'}
    expanded={true}
    index={props.index}
    last={props.last}
  >
    <StepLabel
      // error={option.status === 'invalid'}
      StepIconComponent={QontoStepIcon}
    >
      <Typography sx={{fontWeight: '500'}}>{props.name}</Typography>
    </StepLabel>
    {props.showDescription && <StepContent>
      {props.description}
    </StepContent>}
  </Step>
}

WizardStep.defaultProps = {
  showDescription: true
}

export default WizardStep
