import get from "lodash/get";
import replace from "lodash/replace";
import trim from "lodash/trim";
import qs from "query-string";

const pluralize = require('pluralize')

interface Routes {
  [key: string]: string
}
let ROUTES: Routes = {}

const route = (url: string, name: string, options?: ResourceOptions) => {
  return {
    [name]: url,
  }
}

const resource = (url: string, name: string, options?: ResourceOptions) => {
  const segments = url.split('/')

  const lastSegment = segments[segments.length - 1]
  const alias = options?.parameters?.[lastSegment] as string

  let resourceName: string
  if (alias) {
    resourceName = alias
  } else {
    resourceName = pluralize.singular(lastSegment)
  }

  return {
    [`${name}.index`]: `${url}`,
    [`${name}.create`]: `${url}/create`,
    [`${name}.edit`]: `${url}/[${resourceName}]/edit`,
    [`${name}.show`]: `${url}/[${resourceName}]/show`,
    [`${name}.delete`]: `${url}/[${resourceName}]`,
  }
}

const pull = (routes: object) => {
  ROUTES = {...ROUTES, ...routes}
}

export const clientResource = (url: string, name: string) => {
  const routes = resource(`/client/${trim(url, '/')}`, `client.${name}`)

  pull(routes)
}

export const clientRoute = (url: string, name: string, options?: ResourceOptions) => {
  const routes = route(`/client/${trim(url, '/')}`, `client.${name}`, options)

  pull(routes)
}

export const developerResource = (url: string, name: string, options?: ResourceOptions) => {
  const routes = resource(`/developer/${trim(url, '/')}`, `developer.${name}`, options)

  pull(routes)
}

export const developerRoute = (url: string, name: string, options?: ResourceOptions) => {
  const routes = route(`/developer/${trim(url, '/')}`, `developer.${name}`, options)

  pull(routes)
}

interface ResourceOptions {
  parameters: Record<string, string>
}

export const distributorResource = (url: string, name: string, options?: ResourceOptions) => {
  const routes = resource(`/distributor/${trim(url, '/')}`, `distributor.${name}`, options)

  pull(routes)
}

export const distributorRoute = (url: string, name: string, options?: ResourceOptions) => {
  const routes = route(`/distributor/${trim(url, '/')}`, `distributor.${name}`, options)

  pull(routes)
}

interface Placeholders {
  [key: string]: any
}

export const routeToUrl = (name: string, placeholders: Placeholders = {}): string => {
  let url = get(ROUTES, name)
  if (!url) {
    // console.warn(`Route ${name} not found`)

    return name
  }

  // сериализуем контекст
  if ("context" in placeholders) {
    if (typeof placeholders.context !== 'string') {
      placeholders = {...placeholders, context: JSON.stringify(placeholders.context)}
    }
  }

  // поделим параметры на те который в пути и те которые в параметрах
  let hasMarker: Record<string, any> = {}
  let hasNotMarker: Record<string, any> = {}

  for (const key in placeholders) {
    let position = url.indexOf(`[${key}]`);
    if (position >= 0) {

      hasMarker[key] = placeholders[key]
    } else {
      hasNotMarker[key] = placeholders[key]
    }
  }

  // заменим маркеры в пути
  for (const key in hasMarker) {
    url = replace(url, `[${key}]`, hasMarker[key])
  }

  // остальные параметры добавим в хвост
  if (Object.keys(hasNotMarker).length > 0) {
    url = `${url}?${qs.stringify(hasNotMarker)}`
  }

  return url
}

export const getAllRoutes = () => {
  return ROUTES
}

export const findRouteByValue = (value: string): string | null => {
  for (const key in ROUTES) {
    if (ROUTES[key] === value) {
      return key
    }
  }

  return null
}
