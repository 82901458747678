import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import normalizeUrl from 'normalize-url';
import {useLocation} from "lib/router/useLocation";

const queryString = require('query-string');

let hashParams: {
  modal?: string
} = {};

export const useLocationHash = () => {
  const parse = (locationHash: string) => {
    hashParams = queryString.parse(locationHash)
  }
  const push = (key: string, value: string) => {
    hashParams = {...hashParams, [key]: value}
  }
  const drop = () => {
    delete hashParams['modal'];
  }
  const stringify = () => {
    if (Object.keys(hashParams).length === 0) {
      return '';
    }

    return `#${queryString.stringify(hashParams)}`
  }

  return {parse, push, drop, stringify}
}

/**
 * Если у нас нет имени модального окна, то не храним стей тв хеш паратмерах
 *
 * @param defaultValue
 * @param name
 * @param value
 */
export const useBooleanStateWithHashSyncing = (defaultValue: boolean, {name = 'modal', value}: {name: string, value: string}): [boolean, Function] => {
  const router = useRouter();
  const location = useLocation();
  const [state, setInternalState] = useState(defaultValue);

  useEffect(() => {
    if (value) {
      const hashParams = queryString.parse(location.hash)

      setInternalState(hashParams[name] === value)
    }
  }, [location.hash])

  const setState = (v: boolean) => {
    if (value) {
      let hashParams = queryString.parse(location.hash)

      if (v) {
        hashParams[name] = value;
      } else {
        delete hashParams[name]
      }

      router.push(stringify(hashParams))
    } else {
      setInternalState(v)
    }
  }

  const stringify = (hashParams: object) => {
    if (Object.keys(hashParams).length === 0) {
      return '';
    }

    return `#${queryString.stringify(hashParams)}`
  }


  return [state, setState];
}

export const useLocationHashParameters = () => {
  return queryString.parse(location.hash);
}

export default function compareUrls(firstUrl: string, secondUrl: string) {
  if (firstUrl === secondUrl) {
    return true;
  }

  const options = {
    defaultProtocol: 'https:'
  };

  return normalizeUrl(firstUrl, options) === normalizeUrl(secondUrl, options);
}