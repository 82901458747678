import {create} from 'zustand'

interface ISliderStoreStore {
  index: number
  size: number
  next(): void
  prev(): void
  reset(): void
  setIndex(value: number): void
  setSize(value: number): void
}

const useSliderStore = create<ISliderStoreStore>((set) => ({
  index: 0,
  size: 0,
  next: () => set((state) => ({ index: Math.min(state.size - 1, state.index + 1)})),
  prev: () => set((state) => ({ index: Math.max(0, state.index - 1)})),
  reset: () => set((state) => ({ index: 0, size: 0})),
  setIndex: (value) => set((state) => ({ size: value})),
  setSize: (value) => set((state) => ({ size: value, index: Math.min(state.index, Math.max(0, value - 1))})),
}))

export default useSliderStore
