import styled from "styled-components";
import Image from "next/legacy/image";

const Container = styled.div`
  width: 27px;
  height: 27px;
`

export default () => {
  return (
    <Container>
      <Image src="https://vr.arvilab.com/themes/arvi-vr/assets/favicon.ico" alt="VRP" width={27} height={27} />
    </Container>
  )
}
