import {FCC} from "react";
import Paper from "@mui/material/Paper";
import Box, {BoxProps} from "@mui/material/Box";

const Card: FCC<BoxProps> = (boxProps) => {
  return <Box
    component={Paper}
    {...boxProps}
    sx={{
      padding: '24px 32px',
      ...boxProps.sx
    }}
  >
    {boxProps.children}
  </Box>
}

export default Card