import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ChangesState {
  hasUnsubmittedChanges: boolean
  revision: number
}

const initialState: ChangesState = {
  hasUnsubmittedChanges: false,
  revision: 0,
}

export const changesSlice = createSlice({
  name: 'changes',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.revision += 1
    },
    decrement: (state) => {
      state.revision -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.revision += action.payload
    },
    setHasUnsubmittedChanges: (state, action: PayloadAction<boolean>) => {
      console.log('setHasUnsubmittedChanges', action)

      state.hasUnsubmittedChanges = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setHasUnsubmittedChanges } = changesSlice.actions

export default changesSlice.reducer