import Zendesk, {ZendeskAPI} from "react-zendesk";
import {useCallback, useEffect, useState} from "react";
import {useCurrentUser} from "components/shared/helpers/auth";
import Box from "@mui/material/Box";

const ZENDESK_KEY = "2d70e505-60dd-427d-ba62-90f9093bd630";

const setting = {
  color: {
    theme: "rgba(0, 111, 186, 0.7)"
  },
  position: { horizontal: 'left', vertical: 'bottom' }
}

export const useZendeskSupport = () => {
  return () => {
    const {user} = useCurrentUser()
    const [initialized, setInitialized] = useState(false)
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
      setMounted(true)

      return () => {
        setMounted(false)
      }
    }, [])

    useEffect(() => {
      if (!initialized || !user?.email) {
        return
      }

      ZendeskAPI("webWidget", "identify", {
        name: user.name,
        email: user.email,
      });

    }, [initialized, user])

    const handleLoaded = useCallback(() => {
      ZendeskAPI("webWidget", "setLocale", "en");
      if (mounted) {
        setInitialized(true)
      }
    }, [initialized, mounted])

    return <Box>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={handleLoaded}
      />
    </Box>
  };
}