import {useCurrentUser} from "../../shared/helpers/auth";
import {useRouter} from "next/router";
import {LinearProgress} from "@mui/material";
import {ReactElement} from "react";

const withProfile = (WrappedComponent: ReactElement) => {
  return (props: Record<string, any>) => {
    const router = useRouter()
    const {user, company, isLoading} = useCurrentUser();

    if (!user) {
      return <LinearProgress />
    }

    const enterInStaff = router.asPath.indexOf('/distributor') === 0 && router.asPath.indexOf('/distributor/development') < 0
    if (!!company?.settings?.launcher_auth_mode && enterInStaff) {
      console.log('redirect', '/distributor/development/companies')

      router.push('/distributor/development/companies');
      return <></>;
    }

    // @ts-ignore
    return <WrappedComponent {...props} />
  }
}

export default withProfile