import Stepper from "@mui/material/Stepper";
import {cloneElement} from "react";
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";

interface FullModeProps {
  options: Record<string, any>[]
  onClose(): void
}

const FullMode = (props: FullModeProps) => {
  return <Dialog
    open={true}
  >
    <DialogTitle>
      {"Information is missing"}
    </DialogTitle>

    <DialogContent sx={{marginTop: '0.5rem'}}>
      <Stepper orientation="vertical">
        {
          props.options
            .filter((option) => option.show)
            .map((option, index) => {
              return cloneElement(option.component, {key: index})
            })
        }
      </Stepper>
    </DialogContent>

    <DialogActions>
      <Button onClick={props.onClose} autoFocus>
        Got it
      </Button>
    </DialogActions>
  </Dialog>
}

export default FullMode