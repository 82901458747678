import React, {useMemo} from "react";
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {useClientLayoutPermissions, useCurrentUser} from "components/shared/helpers/auth";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BaseSideMenu, {ISideMenu} from "components/shared/navigations/SideMenu";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';

const SideMenu = () => {
  const {user} = useCurrentUser()
  const {can} = useClientLayoutPermissions()

  const items: ISideMenu['items'] = useMemo(() => {
    let items: ISideMenu['items'] = [
      {
        title: 'Quick Guide',
        icon: FindInPageOutlinedIcon,
        href: '/client/quick-guide',
        permissions: [],
      },
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/client/billing/dashboard',
        permissions: ['viewAny:dashboard'],
      },
      {
        title: 'Library',
        icon: StarBorderOutlinedIcon,
        href: '/client/library/arvi-games',
      },
      {
        title: 'Manage company',
        icon: LocationCityOutlinedIcon,
        children: [
          {
            title: 'My company',
            href: '/client/company',
            permissions: ['viewAny:location', 'viewAny:user', 'viewAny:instance'],
          },
          {
            title: 'Locations',
            href: '/client/locations',
            permissions: ['viewAny:location'],
          },
          {
            title: 'Users',
            href: '/client/users',
            permissions: ['viewAny:user'],
          },
          {
            title: 'Instances',
            href: '/client/instances',
            permissions: ['viewAny:instance'],
          },
          {
            title: 'Agreements',
            href: '/client/company/agreements',
            permissions: ['viewAny:agreement'],
          },
          {
            title: 'Payment methods',
            href: '/client/company/payment-settings/payment-methods',
            permissions: ['viewAny:paymentMethod'],
          },
          {
            title: 'Auto payments',
            href: '/client/company/payment-settings/auto-payments',
            permissions: ['viewAny:paymentMethod'],
          },
        ]
      },
      {
        title: 'Billing',
        icon: RequestQuoteIcon,
        children: [
          {
            title: 'Sessions',
            href: '/client/billing/sessions',
            permissions: ['viewAny:session'],
          },
          {
            title: 'Bills',
            href: '/client/billing/bills',
            permissions: ['viewAny:bill'],
          },
          {
            title: 'Payment confirmations',
            href: '/client/billing/payments',
            permissions: ['viewAny:payment'],
          },
          {
            title: 'Invoices',
            href: '/client/billing/invoices',
            permissions: ['viewAny:invoice'],
          },
          {
            title: 'History',
            href: '/client/billing/history',
            permissions: ['viewAny:history'],
          },
          {
            title: 'Disputes',
            href: '/client/billing/disputes',
            permissions: ['viewAny:dispute'],
          },
        ]
      },
      {
        title: 'Downloads',
        icon: FileDownloadIcon,
        href: '/client/downloads',
        permissions: ['viewAny:downloads'],
      },
      {
        title: 'Profile',
        icon: PersonOutlineOutlinedIcon,
        children: [
          {
            title: 'General',
            href: '/client/profile/general',
          },
          {
            title: 'Password',
            href: '/client/profile/password',
          },
        ]
      },
    ];

    items = items
      .map((item) => {
        item.children = item.children?.filter(child => can(child.permissions))

        return item;
      })
      .filter((item) => {
        return can(item.permissions)
      })
      .filter((item) => {
        return item.children?.length || item.href
      })

    return items;
  }, [user, can])


  if (!user) {
    return null;
  }

  return (
    <BaseSideMenu
      items={items}
    />
  )
}

export default SideMenu