import {useRouter} from "next/router";
import clientTheme from 'themes/client-theme';
import developerTheme from 'themes/developer-theme';
import theme from 'themes/theme';

export const useTheme = () => {
  const router = useRouter()

  return () => {
    if (!router.isReady) {
      return theme
    } else if (router.pathname.startsWith('/client')) {
      return clientTheme
    } else if (router.pathname.startsWith('/developer')) {
      return developerTheme
    }

    return theme
  }
}
