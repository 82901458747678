import SideMenu from "components/clients/navigations/SideMenu";
import {BaseLayout} from "components/shared/layouts/BaseLayout";
import * as React from "react";
import {useMemo} from "react";
import {useZendeskSupport} from "../../shared/zendesk/useZendeskSupport";
import ErrorBoundary from "../../shared/errors/ErrorBoundary";
import Box from "@mui/material/Box";
import {BaseClientLayout} from "../../shared/layouts/BaseClientLayout";

interface LayoutProps {
  maxWidth?: string
}

export const Layout: React.FCC<LayoutProps> = ({
  children, maxWidth,
}) => {
  const ZendeskSupport = useMemo(() => {
    try {
      return useZendeskSupport()
    } catch (e) {
      console.error('useZendeskSupport error', e)

      return <Box></Box>
    }
  }, []);

  return <BaseClientLayout
    sideMenu={<SideMenu />}
    maxWidth={maxWidth}
  >
    {children}
    <ErrorBoundary>
      {/*@ts-ignore*/}
      <ZendeskSupport />
    </ErrorBoundary>
  </BaseClientLayout>
}
