import { createTheme } from '@mui/material/styles';
import { red, lime, green, lightGreen } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    text: {
      primary: '#535353',
      secondary: 'rgba(49,52,53,0.73)',
      disabled: 'rgba(49,52,53,0.43)',
    },
    neutral: {
      main: '#313435',
      contrastText: '#fff',
    },
    primary: {
      main: '#006FBA',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red[400],
    },
    success: {
      main: green[400],
    },
  },
  typography: {
    // htmlFontSize: 10,
    // fontSize: 12,

    button: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: '3px',
          }
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#006FBA',
          // fontSize: 12,
          fontWeight: 500,
          // lineHeight: '100%',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: '0.9285rem',
          borderSpacing: '0 12px',
          borderCollapse: 'separate',
          '.MuiTableCell-root .TableCell-wrapper-root': {
            padding: '3px 16px',
          },
          '.MuiTableCell-head': {
            color: '#BCCADF',
            borderBottom: 'none',
            fontWeight: 600,
            fontSize: 16,
            paddingBottom: '0',
            lineHeight: '100%'
          },
          '.MuiTableCell-body': {
            backgroundColor: '#E5ECF6',
            fontSize: 12,
          },
          '&.plain': {
            borderSpacing: '0',
            borderCollapse: 'separate',
            '.MuiTableCell-root .TableCell-wrapper-root': {
              padding: '3px 16px',
            },
            '.MuiTableCell-body': {
              backgroundColor: '#ffffff',
              fontSize: 12,
            },
          }
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.9285rem',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.FormControl': {
            marginBottom: '5px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '100%',
          color: '#71777A',
          fontWeight: 500,
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-input': {
            height: '14px',
            fontSize: '12px',
            lineHeight: '100%',
            padding: '6px 8px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #71777A',
            borderRadius: '6px',
          }
        }

      }
    },
    MuiPaper: {
      defaultProps: {
        // elevation: 3,
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderColor: '#C1C1C1',
          ...(ownerState.variant === 'outlined' && {
            borderRadius: '8px',
            borderWidth: 1,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '6px',
        },
      },
    },
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
          borderRadius: '6px',
          minWidth: '85px',
          padding: '4px 16px',
          lineHeight: '1.65',

          '&.MuiButton-outlined': {
            padding: '2px 16px',
            borderWidth: '2px',
          },
          '&.MuiButton-outlinedPrimary': {
            borderColor: '#006FBA',
            '&.Mui-disabled': {
              border: '2px solid #006fba40'
            },
          }
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ":root": {
          fontSize: 14
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // Some CSS
          color: '#006FBA',
          fontSize: '32px',
          fontWeight: 600,
          textAlign: 'center',
          padding: '16px 24px 0px 24px',
        },
      },
    }
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    light: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    light?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    light: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    light: true;
  }
}

export default theme;
