import {useCurrentUser} from "components/shared/helpers/auth";
import * as React from 'react';
import Button from '@mui/material/Button';
import {apiWithProfileEmail} from "services/client/profile/email";
import {useWithNotificationOnSubmit} from "components/clients/form/useWithNotificationOnSubmit";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Layout} from "components/clients/layouts/Layout";
import PageContainer from "components/clients/ui/page/PageContainer";
import PageTitle from "components/clients/ui/page/PageTitle";
import {ComponentType, MouseEventHandler, useCallback, useState} from "react";
import {IContentComponentProps} from "../../../../../pages/_app";
import {NextRouter} from "next/dist/shared/lib/router/router";
import Card from "components/clients/ui/card/Card";
import {useSnackbar} from "notistack";
import {useRouter} from "next/router";
import Link from "../../../../Link";

const isExcept = (router: NextRouter) => {
  const pages = [
    '/client/profile/email/verify'
  ];

  return !!pages.find((page) => {
    return page === router.pathname
  })
}

export const withVerifiedEmail = <T, >(WrappedComponent: ComponentType<T>) => {
  return (props: T & IContentComponentProps) => {
    const [emailAlreadyConfirmed, setEmailAlreadyConfirmed] = useState(false)
    const router = useRouter()
    const {user, isSuccess} = useCurrentUser();
    const [resend] = apiWithProfileEmail.useResendMutation<Record<string, any>>();
    const {enqueueSnackbar} = useSnackbar();

    const {onSubmitWithSnackbar: resendWithSnackbar} = useWithNotificationOnSubmit(resend);

    const handleResend = useCallback(() => {
      resend().unwrap()
        .then((response) => {
          console.log('response', response)

          if (response.status === 'fail') {
            enqueueSnackbar(response.message, {
              variant: 'success',
            });

            if (response.reason === 'email_already_confirmed') {
              setEmailAlreadyConfirmed(true)
            }
          } else if (response.status === 'success') {
            enqueueSnackbar(response.message, {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(response.message ?? 'Unknown error', {
              variant: 'error',
            });
          }
        })
    }, [resend])

    const handleReload: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
      e.preventDefault()
      
      router.reload()
    }, [])

    if (isExcept(props.router)) {
      return <WrappedComponent
        {...props}
      />
    }

    if (emailAlreadyConfirmed) {
      return <Layout>
        <PageContainer>
          <PageTitle>Verify Your Email Address</PageTitle>
          <Card>
            Your email address has already been confirmed.
            You may now reload the page or <Link href={router.pathname} onClick={handleReload}>click here</Link>.
          </Card>
        </PageContainer>
      </Layout>
    }

    if (isSuccess && !user?.is_email_verified) {
      return <Layout>
        <PageContainer>
          <PageTitle>Verify Your Email Address</PageTitle>
          <Card>
            Before proceeding, please check your email for a verification link.
            If you did not receive the email <Button onClick={handleResend}>click here to request another</Button>.
          </Card>
        </PageContainer>
      </Layout>
    }

    return <Box>
      <WrappedComponent
        {...props}
      />
    </Box>
  };
}