import {useCallback, useState} from "react";
import {useSnackbar} from "notistack";
import uniqBy from "lodash/uniqBy";

interface IWithNotificationOnSubmitProps {

}

export const useWithNotificationOnSubmit = (mutation: {(data: any): Promise<any>}, onSuccess = (res: any) => {}, onError = (error: any) => {}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [serverValidationErrors, setServerValidationErrors]  = useState<Record<string, any> | undefined>(undefined);

  const onSubmitWithSnackbar = useCallback((data: Record<string, any>): Promise<any> => {
    try {
      return mutation(data)
        // @ts-ignore
        .unwrap()
        .then((res: any) => {
          if (res?.data?.multiple && res?.data?.messages) {
            uniqBy(res?.data?.messages, 'message')
              .map((item: Record<string, any>) => {
                enqueueSnackbar(item.message, {
                  variant: item.status ? 'success' : 'error',
                });
              });
          } else {
            enqueueSnackbar('Success', {
              variant: 'success',
            });
          }

          return Promise.resolve(res)
        })
        .then((res: any) => {
          onSuccess(res);

          return Promise.resolve(res)
        })
        .catch((error: any) => {
          if (error.status === 400 && !!error.data?.details) {
            let errors: Record<string, any> = {}
            for (const errorsKey in error.data?.details) {
              errors[errorsKey] = error.data?.details[errorsKey][0]
            }
            setServerValidationErrors(errors)
          } else if (error.status === 404) {
            enqueueSnackbar('Not found', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(error.data?.data?.message ?? error.data?.error ?? 'Unknown error', {
              variant: 'error',
            });
          }
          onError(error);

          // return new Promise((resolve, reject) => {
          //   return reject(error)
          // })

          return Promise.reject(error);
        })
    } catch (e) {
      console.error('onSubmitWithSnackbar error', e)

      return Promise.reject(e);
    }

  }, [serverValidationErrors])

  return {onSubmitWithSnackbar, serverValidationErrors};
}