import Box from "@mui/material/Box";
import {apiWithCompanyNotifications} from "../../../../services/client/companies/notifications";
import {Alert, AlertTitle, CircularProgress} from "@mui/material";
import PageContainer from "components/clients/ui/page/PageContainer";
import Link from "../../../../Link";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Stack from "@mui/material/Stack";
import useSliderStore from "../../../../lib/slider/useSliderStore";
import {useEffect} from "react";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface CompanyNotificationProps {
  notification: Record<string, any>
}

const CompanyNotification = (props: CompanyNotificationProps) => {
  const [markAsRead, {isLoading: isSubmiting}] = apiWithCompanyNotifications.useMarkAsReadCompanyNotificationMutation()

  const notification = props.notification

  return <Alert
    severity={notification.level}
    action={
      <IconButton color="inherit" size="small" onClick={() => markAsRead(notification)}>
        {isSubmiting ? <CircularProgress size={'1rem'} /> : <CloseIcon/>}
      </IconButton>
    }
  >
    <Box sx={{whiteSpace: 'pre-line'}}><strong>{notification.title}</strong> - {notification.message}</Box>
    <Box pt={1}>
      {notification.actions.map((action: Record<string, any>, index: number) => {
        return <Link href={action.href} key={index}>{action.label}</Link>
      })}
    </Box>
  </Alert>
}


const CompanyNotifications = () => {
  const store = useSliderStore()

  const {data} = apiWithCompanyNotifications.useGetUnreadCompanyNotificationsQuery(undefined, {
    // pollingInterval: 60000, // 1 minute
    refetchOnMountOrArgChange: true,
    // refetchOnReconnect: true,
    // refetchOnFocus: true,
  })

  useEffect(() => {
    store.setSize(data?.data?.length ?? 0)
  }, [data])

  const notification = data?.data?.[store.index]

  if (store.size === 0 || !notification) {
    return null
  }

  const hasPrev = store.index > 0
  const hasNext = store.index < store.size - 1

  return <PageContainer>
    <Box>
      <CompanyNotification notification={notification} />
      {store.size > 1 && <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <IconButton onClick={() => store.prev()} disabled={!hasPrev}><NavigateBeforeIcon/></IconButton>
        <Typography variant={'caption'}>{store.index + 1} / {store.size}</Typography>
        <IconButton onClick={() => store.next()} disabled={!hasNext}><NavigateNextIcon/></IconButton>
      </Stack>}
    </Box>
  </PageContainer>
}

export default CompanyNotifications
