import Logo from "./Logo";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "Link";
import SectionsMenu from "./SectionsMenu";
import {useCurrentUser} from "../helpers/auth";

export default () => {
  const {user, sections} = useCurrentUser()

  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
      sx={{
        padding: '11px 12px'
      }}
    >
      <Link href={'/client'}>
        <Logo />
      </Link>
      <SectionsMenu sections={sections} subheader={user?.email}>ARVI VR</SectionsMenu>
    </Stack>
  )
}
