import Stepper from "@mui/material/Stepper";
import {cloneElement} from "react";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
`

interface CollapsedModeProps {
  options: Record<string, any>[]
  onClose(): void
}

const CollapsedMode = (props: CollapsedModeProps) => {
  return <Box sx={{height: '80px'}}>
    <Container>
      <Box component={Paper} square p={2} sx={{backgroundColor: 'rgb(255, 244, 229)', borderRadius: 0}}>
        <Stack direction={'row'} spacing={8} alignItems={'center'}>
          <Stepper
            sx={{flexGrow: 1}}
          >
            {
              props.options
                .filter((option) => option.show)
                .map((option, index) => {
                  return cloneElement(option.component, {key: index, showDescription: false})
                })
            }
          </Stepper>
          <Button variant={'contained'} onClick={props.onClose} autoFocus>
            Got it
          </Button>
        </Stack>
      </Box>
    </Container>
  </Box>
}

export default CollapsedMode
