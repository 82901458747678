import { clientSplitApi } from '../clientSplitApi'
import {ApiModelResponse, ApiModelsResponse} from "../../common/types";

export const apiWithCompanyNotifications = clientSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getUnreadCompanyNotifications: build.query<ApiModelsResponse<Record<string, any>>, void>({
      query() {
        return {
          url: `/company-notifications`,
          params: {
            status: 'unread'
          }
        }
      },
      providesTags: ['CompanyNotification']
    }),
    markAsReadCompanyNotification: build.mutation<ApiModelResponse, Record<string, any>>({
      query({id}) {
        return {
          url: `/company-notifications/mark-as-read`,
          method: 'PUT',
          body: {id},
        };
      },
      invalidatesTags: ['CompanyNotification']
    }),
  }),
  overrideExisting: false,
});


