import {addMethod, object, number, array, string} from "yup";
import { setLocale } from 'yup';

setLocale({
  mixed: {
    notType: function notType(_ref) {
      return `Value must be a '${_ref.type}' type`
    }
  },
});

addMethod(object, 'uploadedFile', function uploadedFile(anyArgsYouNeed = {}) {
  const { message = 'The file is uploading to the server. Wait for the download to complete.' } = anyArgsYouNeed;
  return this.test('test-name', message, function(value) {
    const { path, createError } = this;

    if (value?.__model === "uploading-file") {
      return createError({ path, message })
    }

    return true;
  });
});

addMethod(number, 'precision', function number(precision, options = {}) {
  const message = options?.message ?? `Value can't have more than ${precision} decimal places`;

  return this.test('precision', message, function(value) {
    const { path, createError } = this;

    if (!value) {
      return true
    }

    if (value.toString().indexOf('.') < 0) {
      return true
    }

    const match = value.toString().match(new RegExp(`^\\d+\\.\\d{0,${precision}}$`))
    if (!match) {
      return createError({ path, message })
    }

    return true;
  });
});

addMethod(
  array,
  'unique',
  function (
    mapper = (a: any) => a,
    message = 'Error text write here'
  ) {
    return this.test('unique', message, function (list) {
      console.log('list', list, list?.map(mapper))

      return list?.length === new Set(list?.map(mapper)).size;
    });
  }
);

addMethod(object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((v: any) => v !== value)
        .some((v: any) => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
        // message: 'Must be unique'
        message: !!message ? message : `The value of the field is duplicated`
      });
    }

    return true;
  });
});

// Функция для удаления HTML тегов
const stripHtml = (html: string) => {
  return html.replace(/<[^>]*>?/gm, '');
};

// Добавление нового метода валидации к yup.string
addMethod(string, 'maxHtmlFreeLength', function (maxLength, message) {
  return this.test(
    'max-html-free-length',  // Уникальное имя для валидатора
    message || `Value must be no more than ${maxLength} characters.`,
    function (value) {
      if (!value) return true; // Пропускаем валидацию, если значение не задано
      const cleanValue = stripHtml(value);
      return cleanValue.length <= maxLength;
    }
  );
});

export const emptyStringToNull = (value: any, originalValue: any) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

declare module 'yup' {
  interface StringSchema {
    maxHtmlFreeLength(maxLength: number, message?: string): this;
  }
  interface NumberSchema {
    precision(precision: number, options?: Record<string, any>): this;
  }
  // @ts-ignore
  interface ObjectSchema {
    uniqueProperty(attributeName: string, message?: string): this;
  }
  interface ArraySchema<T> {
    unique(mapper: (a: T) => T, message?: any): ArraySchema<T>;
  }
}
