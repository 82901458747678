// @ts-nocheck

import { createConfirmation } from 'react-confirm';
import ConfirmDialog from './ConfirmDialog';
import * as React from "react";

// create confirm function
const confirmation = createConfirmation(ConfirmDialog);

// This is optional. But wrapping function makes it easy to use.
export const confirm = (message: string | React.ReactNode, options = {}) => {
  return confirmation({ confirmation: message, options });
}
