import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from 'createEmotionCache';
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack';
import {store} from 'store/store'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {useRouter} from "next/router";
import {SnackbarUtilsConfigurator} from "components/shared/notistack/SnackbarUtilsConfigurator";
import ErrorBoundary from 'components/shared/errors/ErrorBoundary'
import "./styles.css";
import {registerRoutes} from "../src/services/routes/routes";
import 'lib/validation/yup-extended'
import {NextRouter} from "next/dist/shared/lib/router/router";
import FacebookPixel from "../src/components/shared/tracking/FacebookPixel";
import ComponentWrapper from "./ComponentWrapper";
import {useTheme} from "../src/themes/useTheme";
import 'react-image-crop/dist/ReactCrop.css'
import DirtyCheck from "../src/components/shared/forms/dirty/DirtyCheck";
import 'overlayscrollbars/overlayscrollbars.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface IContentComponentProps {
  router: NextRouter
}

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache,
  section?: string,
}

registerRoutes()

const MyApp = function MyApp(props: MyAppProps) {
  const router = useRouter();
  const getTheme = useTheme()
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // @ts-ignore
  const WrappedComponent = ComponentWrapper(Component)

  return (
    <ErrorBoundary>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <FacebookPixel />
      <DirtyCheck />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <ErrorBoundary>
              <SnackbarUtilsConfigurator />
            </ErrorBoundary>

            <CacheProvider value={emotionCache}>
              <Head>
                {/*<meta name="viewport" content="initial-scale=1, width=device-width" />*/}
                <meta name="viewport" content="width=1400, user-scalable=no, initial-scale=1, maximum-scale=1.0" />
                <link rel="shortcut icon" href="https://vr.arvilab.com/themes/arvi-vr/assets/favicon.ico" />
              </Head>
              <ThemeProvider theme={getTheme()}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <WrappedComponent {...pageProps} router={router} />
              </ThemeProvider>
            </CacheProvider>
          </SnackbarProvider>
        </Provider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default MyApp
