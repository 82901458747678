import {FCC} from "react";
import Typography from "@mui/material/Typography";
import Box, {BoxProps} from "@mui/material/Box";

const PageTitle: FCC<BoxProps> = (props) => {
  return <Box textAlign={'center'} onClick={props.onClick}>
    <Typography
      variant='h1'
      color={'primary'}
      fontSize={32}
      fontWeight={600}
      sx={{marginBottom: '12px'}}
    >
      {props.children}
    </Typography>
  </Box>
}

export default PageTitle
