import {useCurrentUser} from "components/shared/helpers/auth";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useWithNotificationOnSubmit} from "components/clients/form/useWithNotificationOnSubmit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import {apiWithEula} from "services/client/profile/eula";
import {ComponentType, useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {IContentComponentProps} from "../../../../../pages/_app";
import {NextRouter} from "next/dist/shared/lib/router/router";

const isExcept = (router: NextRouter) => {
  const pages: string[] = [];

  return !!pages.find((page) => {
    return page === router.pathname
  })
}

export const withEula = <T, >(WrappedComponent: ComponentType<T>) => {
  return (props: T & IContentComponentProps) => {
    const [isScrolled, setScrolled] = useState(false)
    const [accepted, setAccepted] = useState(false);
    const {user} = useCurrentUser();
    // @ts-ignore
    const {data: eula} = apiWithEula.useContentQuery();

    const [proceed] = apiWithEula.useProceedMutation();
    const {onSubmitWithSnackbar: proceedWithSnackbar} = useWithNotificationOnSubmit(proceed);

    if (isExcept(props.router)) {
      return <WrappedComponent
        {...props}
      />
    }

    return <Box>
      <WrappedComponent
        {...props}
      />

      <Box>
        <Dialog
          open={user?.has_accepted_terms_of_use === false}
        >
          <DialogTitle>
            Accept new Terms of Service
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box dangerouslySetInnerHTML={{__html: eula?.data?.text ?? ''}}></Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack p={2} direction='row' justifyContent='space-between' sx={{width: '100%'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accepted}
                    onChange={() => setAccepted(!accepted)}
                  />
                }
                label="I accept Terms of Service as stated above"
                labelPlacement="end"
              />

              <Button variant='outlined' disabled={!accepted}
                      onClick={() => proceedWithSnackbar({id: eula?.data?.id})}>Proceed</Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>

    </Box>
  };
}