// @ts-nocheck

const canUseDOM = (): boolean => {
  return !(typeof window === 'undefined' || !window.document || !window.document.createElement);
}

const hasFbq = (): boolean => {
  return (typeof window !== 'undefined' && !!window?.fbq)
}

export const pageview = () => {
  if (!canUseDOM() || !hasFbq()) {
    return
  }

  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  if (!canUseDOM() || !hasFbq()) {
    return
  }

  window.fbq('track', name, options)
}