import React, {ErrorInfo} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from 'uuid';

class ErrorBoundary extends React.Component {
  constructor(props: {hasError: boolean, id: string }) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = {
      hasError: false,
      id: null,
    }
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, id: null }
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo })

    const id = uuidv4()

    this.setState({ id: id})

    axios.post(`/debug/save-error?id=${id}`, {
      error: error.message,
      trace: error.stack,
      debug: JSON.stringify({ error, errorInfo }),
    })
  }
  render() {
    // Check if the error is thrown
    // @ts-ignore
    if (!!this.state?.hasError) {
      // You can render any custom fallback UI
      return (
        <Box>
          <h2>Oops, there is an error!</h2>

          <Box>
            {/*@ts-ignore*/}
            Error ID: {this.state?.id}
          </Box>
          {/*<button*/}
          {/*  type="button"*/}
          {/*  onClick={() => this.setState({ hasError: false })}*/}
          {/*>*/}
          {/*  Try again?*/}
          {/*</button>*/}
        </Box>
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary