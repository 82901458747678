import React, {FCC} from "react";
import Box from "@mui/material/Box";
import LocationApprovalWizard from "../../locations/approval/LocationApprovalWizard";

const PageContainer: FCC = (props) => {
  return <Box
    sx={{padding: '16px 60px 16px 60px', minWidth: '1060px'}}
  >
    {props.children}
  </Box>
}

export default PageContainer
