import { createTheme } from '@mui/material/styles';
import { red, lime, green, lightGreen } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    text: {
      primary: '#313435',
      secondary: 'rgba(49,52,53,0.73)',
      disabled: 'rgba(49,52,53,0.43)',
    },
    neutral: {
      main: '#313435',
      contrastText: '#fff',
    },
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red[400],
    },
    success: {
      main: green[400],
    },
  },
  typography: {
    // htmlFontSize: 10,
    // fontSize: 12,

    button: {
      fontSize: '1rem',
    },
  },
  components: {
    // MuiChip: {
    //
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.9285rem',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiPaper: {
      defaultProps: {
        // elevation: 0,
        variant: 'outlined',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '6px',
        },
      },
    },
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
          borderRadius: '6px',
          minWidth: '85px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ":root": {
          fontSize: 14
        }
      }
    }
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
  }
}

export default theme;
