import React, {ReactNode} from "react"
import Head from "next/head"
import styled from 'styled-components'
import LogoWithMenu from "../../shared/navigations/LogoWithMenu";
import Box from "@mui/material/Box";
import {Divider} from "@mui/material";
import CompanyNotifications from "../../clients/companies/notifications/CompanyNotifications";
import LocationApprovalWizard from "../../clients/locations/approval/LocationApprovalWizard";

type Props = {
  children?: ReactNode
  sideMenu?: ReactNode
  title?: string
  maxWidth?: string
}

const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 
    "nav content";
  grid-template-rows: 1fr;
  grid-template-columns: 220px minmax(0, 1fr);
  grid-row-gap: 0px;
  grid-column-gap: 0px;
  min-height: 100vh;
  max-width: ${(props: Record<string, any>) => props.maxWidth};
`

const NavigationContainer = styled.div`
  grid-area: nav;
  background-color: #ffffff;
  border-right: 1px solid #e6ecf5;
`
const ContentContainer = styled.div`
  grid-area: content;
  padding: 0;
`

export const BaseClientLayout = ({
  children,
  sideMenu,
  maxWidth = "1400px",
  title = "",
}: Props) => {
  return <Box
    sx={{
      backgroundColor: '#f9fafb',
    }}
  >
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    {/*@ts-ignore*/}
    <GridLayout maxWidth={maxWidth}>
      <NavigationContainer>
        <Box>
          <LogoWithMenu />
        </Box>
        <Divider />
        <Box
          sx={{
            position: 'sticky',
            top: 0
          }}
        >
          {sideMenu}
        </Box>
      </NavigationContainer>
      <ContentContainer>
        <CompanyNotifications />
        {children}
      </ContentContainer>
    </GridLayout>
    <Box sx={{position: ''}}>
      <LocationApprovalWizard />
    </Box>
  </Box>
}
