import {
  clientResource,
  clientRoute,
  developerResource,
  developerRoute,
  distributorResource,
  distributorRoute
} from "./utils";

export const registerClientRoutes = () => {
  clientResource('locations', 'locations')
  clientResource('billing/sessions', 'sessions')

  clientRoute('locations/[location]/arvi-royalties', 'locations.arvi-royalties.index')
  clientRoute('locations/[location]/arvi-royalties/edit', 'locations.arvi-royalties.edit')
}

export const registerDistributorRoutes = () => {
  distributorResource('companies/', 'companies')
  distributorResource('companies/locations', 'locations')
  distributorResource('companies/instances', 'instances')
  distributorResource('companies/users', 'users')
  distributorResource('companies/reminders-section/reminders', 'reminders')
  distributorResource('companies/reminders-section/templates', 'reminder-templates', {
    parameters: {
      templates: 'reminder-template'
    }
  })
  distributorResource('companies/reminders-section/templates/[reminder-template]/locales', 'reminder-templates.locales', {
    parameters: {
      locales: 'reminder-template-locale'
    }
  })
  distributorRoute('companies/[company]', 'companies.home')
  distributorResource('companies/[company]/dashboards', 'companies.dashboards')
  distributorResource('companies/[company]/locations/[location]/prices', 'companies.locations.prices')
  distributorResource('companies/[company]/locations/[location]/discounts', 'companies.locations.discounts')
  distributorResource('companies/[company]/locations/[location]/taxes', 'companies.locations.taxes')
  distributorResource('companies/[company]/locations/[location]/arvi-royalties', 'companies.locations.arvi-royalties')
  distributorResource('companies/[company]/locations', 'companies.locations')
  distributorResource('companies/[company]/bills', 'companies.bills')
  distributorResource('companies/[company]/payments', 'companies.payments')
  distributorResource('companies/[company]/invoices', 'companies.invoices')
  distributorResource('companies/[company]/sessions', 'companies.sessions')
  distributorResource('companies/[company]/users', 'companies.users')
  distributorResource('companies/[company]/users/[user]/assigned-models/[group]', 'companies.users.assigned-models')
  distributorResource('companies/[company]/instances', 'companies.instances')
  distributorResource('companies/[company]/instances/[instance]/links', 'companies.instances.links')
  distributorResource('companies/[company]/installers', 'companies.installers')
  distributorResource('companies/[company]/games', 'companies.games')
  distributorResource('companies/[company]/events', 'companies.events')
  distributorResource('companies/[company]/agreements', 'companies.agreements')
  distributorResource('companies/[company]/settings/company', 'companies.settings.company')
  distributorResource('companies/[company]/settings/nested-companies', 'companies.settings.nested-companies')
  distributorResource('companies/[company]/arvi-royalties', 'companies.arvi-royalties')
  distributorResource('companies/[company]/disputes', 'companies.disputes')
  distributorResource('companies/[company]/balances/[balance]/operations', 'companies.balances.operations')
  distributorResource('companies/[company]/balances', 'companies.balances')
  distributorResource('companies/[company]/transactions', 'companies.transactions')
  distributorResource('companies/[company]/disputes/[dispute]/comments', 'companies.disputes.comments')
  distributorResource('companies/[company]/public-comments', 'companies.public-comments')
  distributorResource('companies/[company]/private-notices', 'companies.private-notices')
  distributorResource('companies/[company]/support-requests', 'companies.support-requests')
  distributorResource('companies/[company]/prepaid-minutes', 'companies.prepaid-minutes')
  distributorResource('companies/[company]/related-companies', 'companies.related-companies')
  distributorResource('companies/[company]/revenue-share-schemas', 'companies.revenue-share-schemas')
  distributorResource('companies/[company]/revenue-share-schemas/[revenue-share-schema]/revenue-shares', 'companies.revenue-share-schemas.revenue-shares')
  distributorResource('companies/[company]/licensing/parameters', 'companies.licensing.parameters', {
    parameters: {
      parameters: 'arvi-royalty'
    }
  })
  distributorResource('companies/[company]/licensing/snapshots', 'companies.licensing.snapshots', {
    parameters: {
      snapshots: 'arvi-royalty-snapshot'
    }
  })
  distributorResource('companies/[company]/reports/ips-activations', 'companies.reports.ips-activations')
  distributorResource('companies/[company]/reports/ips-logins', 'companies.reports.ips-logins')
  distributorResource('companies/[company]/development/games/[game]/prices', 'companies.development.games.prices')
  distributorResource('companies/[company]/development/games', 'companies.development.games')

  distributorResource('companies/markers', 'companies.markers')
  distributorResource('companies/settings/license-parameter-logs', 'companies.settings.license-parameter-logs')
  distributorResource('companies/settings/license-tarification-logs', 'companies.settings.license-tarification-logs')
  distributorResource('companies/settings/arvi-royalty', 'companies.settings.arvi-royalty')
  distributorResource('companies/settings/arvi-royalty/distributor', 'companies.settings.arvi-royalty.distributor')
  distributorResource('companies/settings/arvi-royalty/countries', 'companies.settings.arvi-royalty.countries', {
    parameters: {
      countries: 'arvi-royalty'
    }
  })

  distributorResource('development/companies/[company]/instances', 'development.companies.instances')
  distributorResource('development/companies/[company]/sessions', 'development.companies.sessions')
  distributorResource('development/companies', 'development.companies')
  distributorResource('development/instances', 'development.instances')
  distributorResource('development/sessions', 'development.sessions')

  distributorResource('billing/sessions', 'billing.sessions')
  distributorResource('billing/bills', 'billing.bills')
  distributorResource('billing/bill-units', 'billing.bill-units')
  distributorResource('billing/invoices', 'billing.invoices')
  distributorResource('billing/payments', 'billing.payments')
  distributorResource('billing/auto-payments', 'billing.auto-payments')
  distributorResource('billing/balances', 'billing.balances')
  distributorResource('billing/balance-operations', 'billing.balance-operations')
  distributorResource('billing/disputes', 'billing.disputes')
  distributorResource('billing/disputes/[dispute]/comments', 'billing.disputes.comments')
  distributorResource('billing/contracts', 'billing.contracts')
  distributorResource('billing/credits', 'billing.credits')
  distributorResource('billing/refunds', 'billing.refunds')

  distributorResource('marketing/user-requests', 'marketing.user-requests')
  distributorResource('marketing/user-counters', 'marketing.user-counters')

  distributorResource('marketing/emails/template-groups', 'marketing.emails.template-groups')
  distributorResource('marketing/emails/templates', 'marketing.emails.templates')
  distributorResource('marketing/emails/accounts', 'marketing.emails.accounts')
  distributorResource('marketing/emails/logs', 'marketing.emails.logs')
  distributorResource('marketing/emails/stop-list', 'marketing.emails.stop-list')

  distributorResource('reports/company-licenses', 'reports.company-licenses')
  distributorResource('reports/location-licenses', 'reports.location-licenses')
  distributorResource('reports/ips-activations', 'reports.ips-activations')
  distributorResource('reports/ips-logins', 'reports.ips-logins')
  distributorResource('reports/cohort-analysis-companies', 'reports.cohort-analysis-companies')
  distributorResource('reports/cohort-analysis-games', 'reports.cohort-analysis-games')

  distributorResource('applications/version-requests', 'applications.version-requests')
  distributorResource('applications/version-requests/[version-request]/issues', 'applications.version-requests.issues')
}


export const registerDeveloperRoutes = () => {
  developerRoute('dashboard', 'dashboard.index')

  developerRoute('games/[game]/builds/promo-versions', 'games.builds.promo-versions.index')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/sources', 'games.builds.promo-versions.sources.index')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/videos', 'games.builds.promo-versions.videos.index')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/advertising', 'games.builds.promo-versions.advertising.index')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/images/edit', 'games.builds.promo-versions.images.edit')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/images', 'games.builds.promo-versions.images.index')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/descriptions', 'games.builds.promo-versions.descriptions.index')
  developerRoute('games/[game]/builds/promo-versions/[promo-version]/general-info', 'games.builds.promo-versions.general-info.index')

  // developerRoute('games/[game]/builds/versions/[version]/game-assets/sources', 'games.builds.versions.game-assets.sources.index')
  developerRoute('games/[game]/builds/versions/[version]/game-assets/videos', 'games.builds.versions.game-assets.videos.index')
  developerRoute('games/[game]/builds/versions/[version]/game-assets/advertising', 'games.builds.versions.game-assets.advertising.index')
  developerRoute('games/[game]/builds/versions/[version]/game-assets/images/edit', 'games.builds.versions.game-assets.images.edit')
  developerRoute('games/[game]/builds/versions/[version]/game-assets/images', 'games.builds.versions.game-assets.images.index')
  developerRoute('games/[game]/builds/versions/[version]/game-assets/descriptions', 'games.builds.versions.game-assets.descriptions.index')
  developerRoute('games/[game]/builds/versions/[version]/game-assets/general-info', 'games.builds.versions.game-assets.general-info.index')

  developerRoute('games/[game]/steam-key-uploads/[steam-file-code]/keys', 'games.steam-key-uploads.keys.index')
  developerRoute('games/[game]/steam-key-uploads', 'games.steam-key-uploads.index')

  developerRoute('games/[game]/promo-materials/videos', 'games.promo-materials.videos.index')
  developerRoute('games/[game]/promo-materials/images', 'games.promo-materials.images.index')
  developerRoute('games/[game]/promo-materials/sources', 'games.promo-materials.sources.index')
  developerRoute('games/[game]/promo-materials/advertising', 'games.promo-materials.advertising.index')
  developerRoute('games/[game]/promo-materials', 'games.promo-materials.index')
  developerRoute('games/[game]/statistics', 'games.statistics.index')
  developerRoute('games/[game]/eula', 'games.eula.index')
  developerResource('games/[game]/prices', 'games.prices')
  developerRoute('games/[game]/main', 'games.main')

  developerResource('games/[game]/builds/manifests', 'games.builds.manifests')
  developerRoute('games/[game]/builds/manifests/[manifest]/nested-commands', 'games.builds.manifests.nested-commands')
  developerRoute('games/[game]/builds/manifests/[manifest]/custom-options', 'games.builds.manifests.custom-options')
  developerRoute('games/[game]/builds/manifests/[manifest]/executing', 'games.builds.manifests.executing')
  developerRoute('games/[game]/builds/manifests/[manifest]/language', 'games.builds.manifests.language')
  developerRoute('games/[game]/builds/manifests/[manifest]/edit', 'games.builds.manifests.edit')

  developerRoute('games/[game]/builds/versions/[version]/manifest/nested-commands', 'games.builds.versions.manifests.nested-commands')
  developerRoute('games/[game]/builds/versions/[version]/manifest/custom-options', 'games.builds.versions.manifests.custom-options')
  developerRoute('games/[game]/builds/versions/[version]/manifest/executing', 'games.builds.versions.manifests.executing')
  developerRoute('games/[game]/builds/versions/[version]/manifest/language', 'games.builds.versions.manifests.language')
  developerRoute('games/[game]/builds/versions/[version]/manifest/edit', 'games.builds.versions.manifests.edit')
  developerResource('games/[game]/builds/versions/[version]/files', 'games.builds.versions.files')
  developerRoute('games/[game]/builds/versions/[version]/distribute', 'games.builds.versions.distribute')
  developerResource('games/[game]/builds/versions', 'games.builds.versions', {
    parameters: {
      version: 'application-version'
    }
  })

  developerResource('games/[game]/builds/dev-locations', 'games.builds.dev-locations')
  developerResource('games/[game]/builds/distributions', 'games.builds.distributions')
  developerResource('games/[game]/builds/version-requests', 'games.builds.version-requests')
  developerResource('games/[game]/builds/version-requests/[version-request]/issues', 'games.builds.version-requests.issues')
  developerRoute('games/[queued-change]', 'queued-changes.show')
  developerResource('games', 'games')

  /**
   * Installers
   */
  developerResource('installers/[installer]/builds/distributions', 'installers.builds.distributions')

  developerResource('installers/[installer]/builds/manifests', 'installers.builds.manifests')
  developerRoute('installers/[installer]/builds/manifests/[manifest]/nested-commands', 'installers.builds.manifests.nested-commands')
  developerRoute('installers/[installer]/builds/manifests/[manifest]/custom-options', 'installers.builds.manifests.custom-options')
  developerRoute('installers/[installer]/builds/manifests/[manifest]/executing', 'installers.builds.manifests.executing')
  developerRoute('installers/[installer]/builds/manifests/[manifest]/language', 'installers.builds.manifests.language')
  developerRoute('installers/[installer]/builds/manifests/[manifest]/edit', 'installers.builds.manifests.edit')

  developerRoute('installers/[installer]/builds/versions/[version]/manifest/nested-commands', 'installers.builds.versions.manifests.nested-commands')
  developerRoute('installers/[installer]/builds/versions/[version]/manifest/custom-options', 'installers.builds.versions.manifests.custom-options')
  developerRoute('installers/[installer]/builds/versions/[version]/manifest/executing', 'installers.builds.versions.manifests.executing')
  developerRoute('installers/[installer]/builds/versions/[version]/manifest/language', 'installers.builds.versions.manifests.language')
  developerRoute('installers/[installer]/builds/versions/[version]/manifest/edit', 'installers.builds.versions.manifests.edit')
  developerResource('installers/[installer]/builds/versions/[version]/files', 'installers.builds.versions.files')
  developerRoute('installers/[installer]/builds/versions/[version]/distribute', 'installers.builds.versions.distribute')
  developerResource('installers/[installer]/builds/versions', 'installers.builds.versions', {
    parameters: {
      version: 'application-version'
    }
  })

  developerRoute('installers/[installer]/main', 'installers.main')
  developerResource('installers', 'installers')
  developerResource('users', 'users')

  developerRoute('promo-changes/[promo-change]', 'promo-changes.show')
  developerRoute('promo-changes', 'promo-changes.index')

  developerRoute('sessions', 'sessions.index')

}


export const registerRoutes = () => {
  registerClientRoutes()
  registerDistributorRoutes()
  registerDeveloperRoutes()
}
