import {useRouter} from "next/router";
import {LinearProgress} from "@mui/material";
import {withVerifiedEmail} from "../src/components/clients/profile/email/withVerifiedEmail";
import {withEula} from "../src/components/clients/profile/email/withEula";
import {IContentComponentProps} from "./_app";
import withProfile from "../src/components/clients/profile/withProfile";
import {ReactElement} from "react";


const ComponentWrapper = (WrappedComponent: ReactElement) => {
  const router = useRouter()

  return (props: Record<string, any>) => {
    if (router.pathname.startsWith('/auth')) {
      // @ts-ignore
      return <WrappedComponent {...props} router={router} />
    }

    const ContentComponent = withVerifiedEmail<IContentComponentProps>(withEula<IContentComponentProps>(withProfile(WrappedComponent)))

    return <ContentComponent {...props} router={router} />
  }
}

export default ComponentWrapper