import type {BaseQueryFn, FetchArgs, FetchBaseQueryError,} from '@reduxjs/toolkit/query/react'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const rawBaseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_HOST,
  prepareHeaders(headers) {
    return headers;
  },
  credentials: "include",
})

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const section = window.location.pathname.split('/')?.[1]

  const urlEnd = typeof args === 'string' ? args : args.url
  // construct a dynamically generated portion of the url
  const adjustedUrl = `/api/v2/${section}${!!urlEnd ? `${urlEnd}` : ``}`
  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : {...args, url: adjustedUrl}

  return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const tagTypes = [
  'company', 'email-template', 'reminder', 'reminder-template', 'location', 'invoice', 'payment', 'bill',
  'dispute', 'dispute-comment', 'session', 'public-comment', 'private-notice', 'support-request', 'agreement',
  'prepaid-minute', 'prepaid-report-entry', 'revenue-share-schema', 'revenue-share', 'user', 'assigned-model',
  'tax', 'instance', 'location-price', 'location-discount', 'arvi-royalty', 'location-arvi-royalty', 'arvi-royalty',
  'user-request', 'user-request-distribution', 'user-counter', 'arvi-royalty-snapshot', 'location-approval-request',
  'location-trial-request', 'company-notification', 'company-description', 'preset', 'marker', 'change', 'game',
  'application-version', 'application-version-file', 'release-stage-distribution',
  'publishing-state', 'version-request'
]

// initialize an empty api service that we'll inject endpoints into later as needed
export const distributorSplitApi = createApi({
  reducerPath: 'distributorApi',
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
  tagTypes: tagTypes,
})

// export const splitApi = emptySplitApi as ApiWithInjectedEndpoints<
//   typeof emptySplitApi,
//   [
//     // these are only type imports, no runtime imports -> no bundle dependence
//     typeof import('./locations/locations').apiWithLocations,
//     typeof import('./locations/location-prices').apiWithLocationPrices,
//   ]
// >;
