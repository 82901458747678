import styled from "styled-components";
import React, {useState} from "react";
import CircleIcon from '@mui/icons-material/Circle';
import Link from "Link";
import {Divider, List, ListItem, ListItemIcon} from "@mui/material";
import {useCurrentUser} from "components/shared/helpers/auth";
import Box from "@mui/material/Box";
import {useRouter} from "next/router";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import useSideMenuState from "./useSideMenuState";

const Container = styled.div`

`

export interface ISideMenu {
  items: {
    href?: string
    icon?: any
    title: string
    permissions?: string[]
    children?: {
      href?: string
      icon?: string
      title: string
      permissions?: string[]
    }[]
  }[]
}

const SideMenu: React.FCC<ISideMenu> = ({items = []}) => {
  const router = useRouter()
  const {can} = useCurrentUser()
  const {values: toggleMenuStatus, toggle: setToggleMenuStatus} = useSideMenuState()
  // const [toggleMenuStatus, setToggleMenuStatus] = useState<Record<number, boolean>>({})

  console.log('toggleMenuStatus', toggleMenuStatus)

  return (
    <Container>
      {
        !!items.length && <List
          sx={{
            padding: 0,
            fontSize: 13,
            color: '#505253'
          }}
        >
          {items.filter(item => can(item?.permissions)).map((item, index) => {
            const hasSubMenu = !!item.children?.length
            const open = toggleMenuStatus[index]

            let RootListItem = (props: any) => <ListItemButton {...props} component={Link} href={item?.href ?? '/'} />
            if (hasSubMenu) {
              RootListItem = (props: any) => <ListItemButton {...props} onClick={() => setToggleMenuStatus(index)} />
            }

            return <React.Fragment key={JSON.stringify(item)}>
              <RootListItem
                selected={item?.href === router.pathname}
              >
                <ListItemIcon
                  sx={{ justifyContent: 'left', minWidth: '35px', paddingLeft: '2px', }}
                >
                  {
                    React.createElement(item.icon, {
                      fontSize: 'small',
                      sx: {
                        color: '#C4C4C4'
                      }
                    })
                  }
                </ListItemIcon>

                <Box
                  sx={{fontWeight: '500'}}
                >
                  {item.title}
                </Box>

                {hasSubMenu && <>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </>}
              </RootListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {!!item.children?.length && <>
                  {item.children?.filter((child) => can(child?.permissions))?.map((child, index) => {
                    let css = {}
                    // @ts-ignore
                    if (index === (item?.children?.length - 1)) {
                      css = {
                        padding: '4px 16px 6px 68px',
                      }
                    } else {
                      css = {
                        padding: '4px 16px 4px 68px',
                      }
                    }

                    return <ListItemButton
                      key={child.href}
                      selected={child?.href === router.pathname}
                      sx={css}
                      component={Link}
                      href={child?.href ?? '/'}
                    >
                      <Box
                        fontSize={12}
                        fontWeight={400}
                      >
                        {child.title}
                      </Box>
                    </ListItemButton>
                  })}
                </>}
              </Collapse>

              <Divider />
            </React.Fragment>
          })}

        </List>
      }
    </Container>
  )
}

export default SideMenu