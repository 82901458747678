// @ts-nocheck

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from "react";
import PropTypes from "prop-types";
import { confirmable } from 'react-confirm';

interface IConfirmDialog {
  show: boolean
  proceed(res: boolean): void
  confirmation: string | React.ReactNode
  options: {
    title: string
  }
}

const ConfirmDialog: React.FCC<IConfirmDialog> = ({show, proceed, confirmation, options}) => {
  return (
    <Dialog
      open={show}
      onClose={() => proceed(false)}
    >
      {!!options.title && <DialogTitle>
        {options.title}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText>
          {confirmation}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => proceed(false)}>
          {options.cancelLabel || 'Cancel'}
        </Button>
        <Button variant="contained" onClick={() => proceed(true)}>{options.confirmLabel || 'Confirm'}</Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(ConfirmDialog);