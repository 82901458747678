import {create} from 'zustand'

type Mode = 'full' | 'collapsed'

interface ILocationApprovalWizardStore {
  changed?: Date
  locationId?: string
  show: boolean
  mode: Mode
  setChanged(value: Date): void
  setShow(value: boolean): void
  setLocationId(value: string): void
  setMode(value: Mode): void
}

const useLocationApprovalWizardStore = create<ILocationApprovalWizardStore>((set) => ({
  changed: undefined,
  show: false,
  locationId: undefined,
  mode: 'full',
  setLocationId: (value) => set((state) => ({...state, locationId: value})),
  setShow: (value) => set((state) => ({...state, show: value})),
  setMode: (value) => set((state) => ({...state, mode: value})),
  setChanged: (value) => set((state) => ({...state, changed: value})),
}))

export default useLocationApprovalWizardStore
